export default {
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon bar"])},
    "franchise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidélité"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])}
  },
  "find-bar": {
    "find-your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouve ton"])},
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bar"])}
  },
  "second-section": {
    "main-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services"])},
    "beer-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des verres"])},
    "beer-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peu impôrte tes goûts tu trouveras boisson à ton goût !"])},
    "video-games-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des jeux"])},
    "video-games-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consoles rétro / next-gen, bornes d’arcade, de quoi satisfaire les grands et les petits ! "])},
    "card-games-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux de société"])},
    "card-games-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan de jeux de société, ou les jeux de carte ? Viens passer du bon temps et nous montrer tes talents ! "])},
    "events-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des événements"])},
    "events-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournoi, Karaoké, Blind Test, ton reload organise des événements tout les soirs !"])}
  },
  "third-section": {
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crée ton compte"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenant"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscris toi ici et tu pourras recevoir le planning du mois de ton bar favori ! Réserver ta table pour le prochain karaoké ! Cumulé des points de fidélité pour gagner des verres ! Et bien plus encore …"])}
  },
  "footer": {
    "legal-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales / CGU"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "franchise": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La franchise"])},
    "missing-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ Obligatoire"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "civil-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Marital"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "operators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitant"])},
    "contributions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
    "residence-town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville de résidence"])},
    "location-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville d'implantation"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présente toi"])},
    "why-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi notre franchise ?"])},
    "info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu souhaites rejoindre une aventure dynamique et hors du commun ? Partager ton amour pour la pop culture et le divertissement ? Prendre un tout nouveau départ ? Te reconvertir ? T’épanouir dans l'entreprenariat est ton objectif ? On t’accompagnera du début à la finalité de ton projet !!! N’attends plus et commence la quête (!) Et elle débute avec le petit quiz en dessous :"])},
    "info-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Céline Legru"])},
    "info-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable de la franchise"])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Champs Obligatoires"])},
    "send-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "civil-status-options": {
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionne une option"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célibataire"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marié/Pacsé"])},
      "divorce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divorcé"])},
      "widow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuf/Veuve"])},
      "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En couple"])}
    },
    "operators-options": {
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionne une option"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul"])},
      "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En couple"])},
      "with-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec des associés"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar concerné"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton message"])},
    "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionne une option"])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Champs Obligatoires"])},
    "send-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "subscription": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pseudo"])},
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar concerné"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe"])},
    "already-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mail à déjà un compte existant"])},
    "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionne une option"])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Champs Obligatoires"])},
    "bad-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit faire 8 caractères et contenir une majuscule, un chiffre et un caractère spécial"])},
    "different-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe sont différents"])},
    "accept-cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter les CGU"])},
    "need-accept-cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu dois accepter les CGU"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte"])},
    "already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà un compte ?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "log-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte ?"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte"])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Champs Obligatoires"])},
    "bad-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail ou le mot de passe est incorrect."])},
    "forget-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir mon code"])},
    "mail-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail envoyé"])}
  },
  "bar-details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos pratiques"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "monthly-planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning du mois"])},
    "book-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve ta table"])},
    "book-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez en ligne dès maintenant et assurez-vous d’avoir une table pour notre prochain événement !"])},
    "book-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])}
  },
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchisé"])},
    "refuse-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'as pas les droits pour accéder à cette page"])},
    "add-bar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un bar"])}
    },
    "add-franchise": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un franchisé"])}
    },
    "has-karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispose d'un karaoké"])},
    "max-reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max réservations"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
  },
  "planning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])}
  },
  "add-planning": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un planning"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement"])}
  },
  "reservation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "peoples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de personnes"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "book-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
    "need-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone est obligatoire"])},
    "max-reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas réserver plus de 3 fois."])},
    "no-reservations-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a plus de table disponible à cette date..."])}
  },
  "account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])}
  },
  "reservation-list": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes réservations"])},
    "peoples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnes"])}
  },
  "generated-qr-code": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
    "download-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
  },
  "scan-user": {
    "btn-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner un client"])},
    "add-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créditer"])},
    "search-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
  },
  "bar-goal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de points"])},
    "add-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un objectif"])}
  },
  "fidelity-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma fidelité"])}
  },
  "user-resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé fidélité"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "see-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le détail"])}
  },
  "scan-bar": {
    "btn-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton compte vient d'être crédité de 10 points ! 🎉"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu as déjà scanné le qr code aujourd'hui petit filou !"])}
  },
  "params-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "edit-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifie ton mail"])},
    "edit-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifie ton mot de passe"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme ton mot de passe"])},
    "bad-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais mot de passe"])},
    "success-email-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Modifié"])},
    "password-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe modifié"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
  },
  "legal-notice-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales / CGU"])},
    "enterprise-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "enterprise-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.L. GROUPE, société par actions simplifiée, immatriculée sous le SIREN 877812172 dont le siége social est situé au 104 RUE DE LA MAIRIE - 59500 DOUAI. Numéro de téléphone: 06 03 19 84 78."])},
    "director-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directrice de la publication"])},
    "director-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La responsable de la publication est Céline Legru."])},
    "host-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeur"])},
    "host-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le site est hébergé par Google Cloud Platform, situé à Francfort en Allemagne."])},
    "data-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des données"])},
    "data-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le site assue à l'utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés."])},
    "data-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En vertu de la loi informatique et libertés, en date du 6 janvier 1978, l'utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'utiliseur peut exercer ce droit via un formulaire de contact disponible sur le site web."])},
    "data-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client renseignant ses coordonées (pseudo, mail et numéro de téléphone) autorise ML Groupe à le contacter dans le cadre d'une réservation"])},
    "data-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML Groupe s'engage à ne pas transmettre les données personnelles de ses utilisateurs à une société extérieure au groupe ML Groupe, hors requête d'une autorité habillitée par la loi et dans le cadre des dispositions légales en vigueur."])}
  },
  "confirm-mail-sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mail à bien été envoyé, vous recevrez une réponse prochainement."])}
}